import React from 'react'
import Layout from '../components/layout'
import Promotion from '../components/forms/free-promotion'
import Seo from '../components/seo'

const FreePromotionFormPage = ({ location }) => (
  <Layout location={location}>
    <Promotion />
  </Layout>
)

export const Head = ({ location }) => {
  const title = 'Contact Code Staff: We make Development Easy'
  const description =
    'Please fill out a form with your updated information to receive more information about Code Staff or call 855-435-CODE'

  return <Seo type="page" title={title} description={description} />
}

export default FreePromotionFormPage
